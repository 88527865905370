// import { showToast } from "../utils/commonUtils";
import { storage } from "./firebase";
import axios from "axios";

export const uploadMedia = (file, type, fileName, cb) => {
  return new Promise((res, rej) => {
    try {
      const uploadTask = storage.ref(`/${type}/${fileName}`).put(file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          var progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          cb(progress);
        },
        (err) => {
          console.log(err);
        },
        () => {
          storage
            .ref(type)
            .child(fileName)
            .getDownloadURL()
            .then((fireBaseUrl) => {
              res(fireBaseUrl);
            });
        }
      );
    } catch (err) {
      rej(err);
      console.error(err);
    }
  });
};

// export const uploadMediaVideo = (
//   file,
//   type,
//   fileName,
//   videoId,
//   policy,
//   fileType,
//   cb
// ) => {
//   // console.log(file.size / 1024);
//   return new Promise((res, rej) => {
//     const uploadTask = storage.ref(`/${type}/${fileName}`).put(file);

//     // Updating Video status to uploading
//     VideoManager._put
//       .updateVideoProperty({
//         id: videoId,
//         data: Video_States.UPLOADING,
//         property: "status",
//       })
//       .then(() => {
//         uploadTask.on(
//           "state_changed",
//           (snapshot) => {
//             var progress = Math.round(
//               (snapshot.bytesTransferred / snapshot.totalBytes) * 100
//             );
//             cb(progress);
//           },
//           (err) => {
//             // Upload video status upload_err
//             VideoManager._put
//               .updateVideoProperty({
//                 id: videoId,
//                 data: Video_States.UPLOAD_ERR,
//                 property: "status",
//               })
//               .then(() => {
//                 console.log(err);
//                 showToast(
//                   "Error occured while uploading video, please try again !",
//                   { type: "error" }
//                 );
//                 rej("Error occured while uploading video, please try again !");
//               })
//               .catch((err) => {
//                 console.log(err);
//                 rej(
//                   "Error occured while updating status to upload err, please try again !"
//                 );
//               });
//           },
//           () => {
//             storage
//               .ref(type)
//               .child(fileName)
//               .getDownloadURL()
//               .then((fireBaseUrl) => {
//                 try {
//                   console.log("firebaseUrl", fireBaseUrl);
//                   sendTranscodeRequest(fireBaseUrl, videoId, policy, fileType)
//                     .then((resp) => {
//                       console.log("Send for transcoding, ", resp);
//                       showToast("Video Updated Successfully");
//                       res(true);
//                     })
//                     .catch((err) => {
//                       console.error(err);
//                       showToast(
//                         "Error occured while transcoding, please try again !",
//                         {
//                           type: "error",
//                         }
//                       );
//                       rej(
//                         "Error occured while transcoding, please try again !"
//                       );
//                     });
//                 } catch (error) {
//                   console.error(error);
//                   rej(error.message);
//                 }
//               });
//           }
//         );
//       })
//       .catch((err) => {
//         console.error(err);
//         rej(
//           "Error occured while updating status to uploading, please try again !"
//         );
//       });
//   });
// };

// export const deleteMedia = async (type) => {
//   try {
//     // console.log('Deleting media :: ', `/${type}/${fileName}`);
//     await storage.refFromURL(type).delete();

//     // console.log("Media Deleted", `/${type}/${fileName}`);
//     return `/${type}`;
//   } catch (error) {
//     console.error("Error deleting media", error);
//     return;
//   }
// };
