import React, { useContext, useEffect, useState } from "react";
import logo from "../../Assets/images/logo.png";
import { login } from "../../firebase/firebase";
import styles from "./index.module.scss";

const Login = () => {
  const [err, setErr] = useState(null);
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const verifyForm = async () => {
    if (
      formValues.email.length > 0 &&
      formValues.password.length > 0 &&
      validateEmail(formValues.email)
    ) {
      login(formValues.email, formValues.password)
        .then((res) => {
          setErr(null);
        })
        .catch((err) => {
          setErr("Invalid Login");
          return false;
        });
    } else {
      setErr("Invalid Login");
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyForm();
  };

  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className={styles.loginWrapper}>
      <form onSubmit={(e) => handleSubmit(e)} className={styles.loginBox}>
        <div className={styles.header}>
          <img src={logo} alt="log" />
          Login
        </div>
        <div className={styles.inputWrapper}>
          <label>Email</label>
          <input
            value={formValues.email}
            type="text"
            name="email"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Value"
            className={styles.input}
          />
        </div>
        <div className={styles.inputWrapper}>
          <label>Password</label>
          <input
            value={formValues.password}
            type="password"
            name="password"
            onChange={(e) => handleChange(e)}
            placeholder="Enter Value"
            className={styles.input}
          />
        </div>
        <button type="submit" className={styles.btn}>
          Submit
        </button>
        {err && <div className={styles.err}>{err}</div>}
      </form>
    </div>
  );
};

export default Login;
