
export const HotspotIdToAnalyticsName = {
    "Brand Room Screen_Left_2": "Brand_Room_BigPoster_left",
    "Brand Room Screen_Right_2": "Brand_Room_BigPoster_Right",
    "Brand Room Screen_Right_1": "Brand_Room_SmallPoster_Right",
    "Brand Room Screen_Left_1": "Brand_Room_SmallPoster_Left",
    "liveRoomScreen01": "Brand_Room_Screen",

    "lobby_rightScreen": "Lobby_SmallPoster_Right",
    "lobby_leftScreen": "Lobby_SmallPoster_Left",
    "Lobby_Big_Screen1": "Lobby_BigScrren_Video_Right",
    "Lobby_Big_Screen2": "Lobby_BigScrren_Video_Left",

    "game2": "Unscramble",
    "game1": "TrumpCards",
    "Play_Area_Screen_Right": "GameZone_SmallPoster_Right",
    "Play_Area_Screen_Left": "GameZone_SmallPoster_left",

    "Stats Room Screen_6": "StatsRoom_Poster_6",
    "Stats Room Screen_5": "StatsRoom_Poster_5",
    "Stats Room Screen_4": "StatsRoom_Poster_4",
    "Stats Room Screen_5": "StatsRoom_Poster_5",
    "Stats Room Screen_2": "StatsRoom_Poster_2",
    "Stats Room Screen_3": "StatsRoom_Poster_3",
    "Stats Room Screen_1": "StatsRoom_Poster_1",

    "link_askCricinfo": "AskCricInfo",

    "Live Show Screen_Right": "LiveRoom_SmallPoster_Right",
    "Live Show Screen_Left": "LiveRoom_SmallPoster_Left",
    "brandRoomScreen01": "LiveScreen",

    "LiveChat": "LiveChat",
    "one-on-oneChat": "AvatarChat",
    "carHotspot": "CarHotspot"
}