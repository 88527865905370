import React, { useContext } from "react";
import { ScreenContext } from "../../Contexts/ScreenContext/ScreenContextProvider";
import styles from "./index.module.scss";
import logo from "../../Assets/images/logo.png";
import { auth } from "../../firebase/firebase";

const MENU_IDS = [
  { id: "GameRoom", label: "Game Room" },
  { id: "StatsRoom", label: "Stats Room" },
  { id: "BrandRoom", label: "Brand Room" },
  { id: "LobbyRoom", label: "Lobby Room" },
  { id: "LiveShow", label: "Live Show" },
  { id: "vehicle", label: "Vehicle" },
];

function Nav() {
  const { sideScreen, setSideScreen } = useContext(ScreenContext);

  const handleItemClick = (menu) => {
    setSideScreen(menu.id);
  };

  const handleSignOut = () => {
    auth.signOut();
  };

  return (
    <>
      <div className={styles.navWrapper}>
        <img className={styles.logo} src={logo} alt="logo" />
        <ul>
          {Object.values(MENU_IDS).map((menu, index) => (
            <li
              onClick={() => handleItemClick(menu)}
              style={{
                backgroundColor:
                  sideScreen === menu.id ? "#1425FF" : "transparent",
              }}
              key={index}
            >
              {menu.label}
              <span>{`${sideScreen === menu.id ? ">" : ""}`}</span>
            </li>
          ))}
        </ul>
        <div onClick={handleSignOut} className={styles.signout}>
          Sign Out
        </div>
      </div>
    </>
  );
}

export default Nav;
