import React, { useContext, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { UserContext } from "../../Contexts/UserContext/UserContextProvider";
import Login from "../Login";
import MainScreen from "../MainScreen";

const AllRoutes = () => {
  const { user, loading } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate("dashboard", { replace: true });
    } else {
      navigate("/", { replace: true });
    }
  }, [user]);
  return loading ? (
    <div className="loadingWrapper">loading...</div>
  ) : (
    <Routes>
      <Route exact path={"/"} element={<Login />} />
      <Route exact path={"/dashboard"} element={<MainScreen />} />
    </Routes>
  );
};

export default AllRoutes;
