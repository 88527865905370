import React from "react";

const Loader = () => {
  return (
    <div class="loadingio-spinner-rolling-bp1r5hpx2pt">
      <div class="ldio-kl09nexvvim">
        <div></div>
      </div>
    </div>
  );
};

export default Loader;
