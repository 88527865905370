import { createContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase/firebase";

export const UserContext = createContext();

export default function UserContextProvider(props) {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("userAuth"))
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem("userAuth"))) {
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [JSON.parse(localStorage.getItem("userAuth"))]);

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        if (user.email === "admin@espn.com") {
          localStorage.setItem("userAuth", JSON.stringify(user));
          setUser({ ...user, isChecked: true });
        } else {
          console.log(user, "auth changed");
        }
      } else {
        localStorage.clear();
        setUser(null);
      }
    });
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
