import React, { useContext, useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import uploader from "../../Assets/images/uploader.png";
import { uploadMedia } from "../../firebase/Storage";
import { ScreenContext } from "../../Contexts/ScreenContext/ScreenContextProvider";
import AssetsManager from "../../Managers/Assets";
import success from "../../Assets/images/success.svg";
import { HotspotIdToAnalyticsName } from "../../Constants/NamesConstant";
import info from "../../Assets/images/info.png";

const TypeNames = {
  Canvas: "Platform",
  React: "Popup",
};
const LIVE_ROOM_ID = "brandRoomScreen01";
const detailTypes = {
  canvasLink: "canvasLink",
  link: "link",
  canvasVideoType: "canvasVideoType",
};

const DualUploader = ({ detail }) => {
  const { handleTypeChange, allData } = useContext(ScreenContext);
  const [loader, setLoader] = useState({
    loader1Progress: 0,
    loader2Progress: 0,
  });
  const prevRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const onToggle = (e) => {
    let value = e.target.checked ? "rss" : "mp4";
    if (prevRef.current !== null) {
      handleTypeChange(
        "canvasLink",
        JSON.parse(JSON.stringify(prevRef.current)),
        detail.id
      );
    } else {
      handleTypeChange("canvasLink", "", detail.id);
    }
    prevRef.current = detail.canvasLink;
    handleTypeChange("canvasVideoType", value, detail.id);
  };

  const handleImageUpload = async (e, type) => {
    if (e.target.files[0]) {
      let ref = `assets/image/${type}`;
      let res = await uploadMedia(
        e.target.files[0],
        ref,
        `${detail.id + type}?x=${Math.random()}`,
        (progress) => {
          if (progress >= 100) alert("uploaded!!");
          let val = 0;
          if (type === detailTypes.canvasLink) {
            val = 1;
          } else {
            val = 2;
          }
          setLoader((prev) => ({
            ...prev,
            [`loader${val}Progress`]: progress,
          }));
        }
      );
      handleTypeChange(type, res, detail.id);
    }
  };

  const handleInputName = (e, type, RssType) => {
    e.preventDefault();
    handleTypeChange(type, e.target.value, detail.id);
  };

  const handleVideoUplaod = async (e, type) => {
    if (e.target.files[0]) {
      let ref = `assets/video/${type}`;
      let res = await uploadMedia(
        e.target.files[0],
        ref,
        `${detail.id + type}?x=${Math.random()}`,
        (progress) => {
          let val = 0;
          if (type === detailTypes.canvasLink) {
            val = 1;
          } else {
            val = 2;
          }
          setLoader((prev) => ({
            ...prev,
            [`loader${val}Progress`]: progress,
          }));
        }
      );
      handleTypeChange(type, res, detail.id);
    }
  };

  const validateForm = (link) => {
    const yt_regexp = /^http:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]*)/;
    const vimeo_regexp = /^http:\/\/www\.vimeo\.com\/(\d+)/;
    return vimeo_regexp.test(String(link).toLowerCase());
  };

  const handleSave = () => {
    setLoader({
      loader1Progress: 0,
      loader2Progress: 0,
    });
    AssetsManager._put.updateAsset(
      { data: detail, property: detail.id },
      (res) => {
        if (res) {
          setShowModal(true);
        }
      }
    );
  };

  const VideoType = (typeName) => {
    return (
      <>
        <div className={styles.banner1Btns}>
          {detail[typeName] ? (
            <video
              autoPlay
              loop="true"
              muted={true}
              className={styles.detailLink}
              src={detail[typeName]}
              alt="detail"
            />
          ) : (
            <div className={styles.bannerImg}>{`${1080}x${1920} px`}</div>
          )}
          <div className={styles.banner1BtnsWrapper}>
            <input
              id={detail.id + typeName}
              onChange={(e) => handleVideoUplaod(e, typeName)}
              type="file"
              accept=".mp4"
              hidden
            />
            <label
              htmlFor={detail.id + typeName}
              type="submit"
              className={styles.banner1Btn}
            >
              <img src={uploader} alt="uploader" />
              Video
            </label>
            <div className={styles.bannerText}>
              <div>{`Supported file: mp4`}</div>
              <div>{`Max file size: 1mb`}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ImageType = (typeName) => {
    return (
      <>
        <div className={styles.banner1Btns}>
          {detail[typeName] ? (
            <img
              className={styles.detailLink}
              src={detail[typeName]}
              alt="detail"
            />
          ) : (
            <div className={styles.bannerImg}>{`${1080}x${1920} px`}</div>
          )}
          <div className={styles.banner1BtnsWrapper}>
            <input
              id={detail.id + typeName}
              onChange={(e) => handleImageUpload(e, typeName)}
              type="file"
              accept="Image/*"
              hidden
            />
            <label
              htmlFor={detail.id + typeName}
              type="submit"
              className={styles.banner1Btn}
            >
              <img src={uploader} alt="uploader" />
              Image
            </label>
            <div className={styles.bannerText}>
              <div>{`Supported file: PNG/ JPEG in ${1080} x ${1920} px`}</div>
              <div>{`Max file size: 1mb`}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const VimeoType = (RssType, typeName) => {
    // let typeName = 'video'
    return (
      <>
        <div className={styles.vimeoType}>
          <input
            key="vimeoLink"
            type="text"
            onChange={(e) => handleInputName(e, typeName, RssType)}
            value={detail[typeName] ? detail[typeName] : ""}
            placeholder="Add video link"
            className={styles.addUrl}
          />
        </div>
      </>
    );
  };

  const PdfType = ({ typeName }) => {
    return (
      <>
        <div className={styles.banner1Btns}>
          <div className={styles.banner1BtnsWrapper}>
            <input type="file" accept="Image/*" hidden />
            <label type="submit" className={styles.banner1Btn}>
              <img src={uploader} alt="uploader" />
              PDF
            </label>
            <div className={styles.bannerText}>
              <div>{`Supported file: PNG/ JPEG in ${detail.imageDimensionWidth} x ${detail.imageDimensionHeight} px`}</div>
              <div>{`Max file size: ${detail.maxFileSize}`}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const HandleBanner = (banner, type, typeName) => {
    switch (type) {
      case "mp4":
      case "video":
        return VideoType(typeName);
      case "rss":
        return VimeoType(true, typeName);
      case "image":
        return ImageType(typeName);
      case "pdf":
        return PdfType(typeName);
      case "vimeo":
        return VimeoType(false, typeName);
      default:
        return ImageType(typeName);
    }
  };

  const checkForChange = () => {
    if (
      detail.canvasLink !== allData[detail.id].canvasLink ||
      detail.link !== allData[detail.id].link
    ) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles.dualUploadWrapper}>
      <div className={styles.dualUploadContainer}>
        {showModal && (
          <div className="modalWrapper">
            <div
              onClick={() => setShowModal(false)}
              className="modalOverlay"
            ></div>
            <div className="modal">
              <div className={styles.successImg}>
                <img src={success} alt="success" />
              </div>
              Succesfully Updated
            </div>
          </div>
        )}
        {showPreviewModal && (
          <div className="modalWrapper">
            <div
              onClick={() => setShowPreviewModal(false)}
              className="modalOverlay"
            ></div>
            <div
              style={{ backgroundColor: "transparent", padding: 0 }}
              className="modal"
            >
              <div className={styles.prevImg}>
                <img
                  src={`/${HotspotIdToAnalyticsName[detail.id]}.png`}
                  alt="success"
                />
              </div>
            </div>
          </div>
        )}
        <div className={styles.highlight}>
          <div className={styles.highlightText}>
            <div className={styles.name}>
              {HotspotIdToAnalyticsName[detail.id]}
            </div>
            <div className={styles.location}>{detail.Label}</div>
          </div>

          <div className={styles.highlightBtns}>
            <img
              onClick={() => setShowPreviewModal(true)}
              className={styles.info}
              src={info}
              alt="img"
            />
            <div
              style={{
                backgroundColor: checkForChange() ? "#1425ff" : "#d4d4d4",
              }}
              onClick={handleSave}
              className={styles.highlightBtn1}
            >
              Save
            </div>
          </div>
        </div>
        <div
          style={{
            gridTemplateColumns: "1fr 1fr",
          }}
          className={styles.banners}
        >
          <div className={styles.banner}>
            {detail.id === LIVE_ROOM_ID ? (
              <>
                <div className={styles.headerWrapper}>
                  <div>{TypeNames.Canvas}</div>
                  <div className={styles.checkbox}>
                    <strong
                      style={{
                        opacity: detail.canvasVideoType != "mp4" ? 0.65 : 1,
                        color:
                          detail.canvasVideoType == "mp4"
                            ? "#004eff"
                            : "#63b5e2",
                      }}
                    >
                      MP4
                    </strong>
                    <label className="toggle-switch">
                      <input
                        type="checkbox"
                        checked={detail.canvasVideoType === "rss"}
                        onChange={onToggle}
                      />
                      <span className="switch" />
                    </label>
                    <strong
                      style={{
                        opacity: detail.canvasVideoType != "rss" ? 0.65 : 1,
                        color:
                          detail.canvasVideoType == "rss"
                            ? "#004eff"
                            : "#63b5e2",
                      }}
                    >
                      RSS
                    </strong>
                  </div>
                </div>
                {HandleBanner(
                  1,
                  detail.canvasVideoType,
                  detailTypes.canvasLink
                )}
              </>
            ) : (
              <>
                {" "}
                <div>{TypeNames.Canvas}</div>
                {HandleBanner(1, detail.canvasType, detailTypes.canvasLink)}
              </>
            )}
            <div className={styles.loader}>
              <div
                style={{ width: `${loader.loader1Progress}%` }}
                className={styles.progress}
              ></div>
            </div>
          </div>

          {detail.canvasVideoType !== "rss" && (
            <div className={styles.banner}>
              <div>{TypeNames.React}</div>{" "}
              {HandleBanner(
                2,
                detail.type == "video" ? "vimeo" : detail.type,
                detailTypes.link
              )}
              <div className={styles.loader}>
                <div
                  style={{ width: `${loader.loader2Progress}%` }}
                  className={styles.progress}
                ></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default DualUploader;
