import React, {
  createContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import AssetsManager from "../../Managers/Assets";

export const ScreenContext = createContext(null);

const detailsList = {
  GameRoom: ["Play_Area_Screen_Right", "Play_Area_Screen_Left"],
  BrandRoom: [
    "Brand Room Screen_Left_1",
    "Brand Room Screen_Left_2",
    "Brand Room Screen_Right_1",
    "Brand Room Screen_Right_2",
    "liveRoomScreen01", // it is in brandRoom, it is not mistaken
  ],
  LiveShow: [
    "Live Show Screen_Left",
    "Live Show Screen_Right",
    "brandRoomScreen01", //it is in liveShow, it is not mistaken
  ],
  LobbyRoom: [
    "Lobby_Big_Screen1",
    "Lobby_Big_Screen2",
    "lobby_leftScreen",
    "lobby_rightScreen",
  ],
  StatsRoom: [
    "Stats Room Screen_1",
    "Stats Room Screen_2",
    "Stats Room Screen_3",
    "Stats Room Screen_4",
    "Stats Room Screen_5",
    "Stats Room Screen_6",
  ],
};

const initialState = {};
const Action_Type = {
  handleTypeChange: "handleTypeChange",
  handleInitialState: "handleInitialState",
  handleInputChange: "handleInputChange",
  handleError: "handleError",
  loading: "loading",
};

const reducer = (state, action) => {
  let { type, payload } = action;
  switch (type) {
    case Action_Type.handleInitialState:
      return { ...payload };
    case Action_Type.handleTypeChange:
      let _prev = { ...state };
      _prev[payload.id][payload.type] = payload.payload;
      return _prev;
    case Action_Type.handleInputChange:
      return { ...state, [payload.name]: payload.value };
    case Action_Type.handleError:
      return { ...state, ...payload };
    case Action_Type.loading:
      return { ...state, ...payload };
    default:
      return { ...state };
  }
};

export default function ScreenContextProvider(props) {
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sideScreen, setSideScreen] = useState("GameRoom");
  const [vehicleData, setVehicleData] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const rawDataRef = useRef(null);

  useEffect(() => {
    getAllAssets();
    getAllVehicles();
    return () => {
      AssetsManager._get.removeAssetsListener();
      AssetsManager._get.removeVehicleListener();
    };
  }, []);

  useEffect(() => {
    if (sideScreen) {
      if (rawDataRef && rawDataRef.current) {
        if (sideScreen === "vehicle") return;
        let obj = {};
        detailsList[sideScreen] &&
          detailsList[sideScreen].forEach((detail) => {
            if (rawDataRef.current.hasOwnProperty(detail)) {
              obj[detail] = rawDataRef.current[detail];
            }
          });
        dispatch({
          type: Action_Type.handleInitialState,
          payload: JSON.parse(JSON.stringify(obj)),
        });
        setLoading(false);
      }
    }
  }, [sideScreen, rawDataRef.current]);

  const getAllAssets = async () => {
    AssetsManager._get.getAssets((res) => {
      rawDataRef.current = res;
      setToggle((prev) => !prev);
    });
  };
  const getAllVehicles = async () => {
    AssetsManager._get.getVehicles((res) => {
      setVehicleData(res.urls);
    });
  };

  const handleTypeChange = (type, payload, id) => {
    dispatch({
      type: Action_Type.handleTypeChange,
      payload: { type, payload, id },
    });
  };

  return (
    <ScreenContext.Provider
      value={{
        sideScreen,
        setSideScreen,
        details: state,
        handleTypeChange,
        vehicleData,
        setVehicleData,
        loading,
        allData: rawDataRef.current,
      }}
    >
      {props.children}
    </ScreenContext.Provider>
  );
}
