import {
  ASSETS_COLLECTION,
  ASSETS_COLLECTION_DEV,
} from "../../Constants/CollectionConstants";
import firebase, { firestore } from "../../firebase/firebase";

const updateAsset = async ({ data, property }, cb) => {
  try {
    if (!data || !property) throw new Error("Provide all arguments");
    // let docRef = firestore.collection(ASSETS_COLLECTION).doc("lobby");
    let serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
    let docRefDev = firestore.collection(ASSETS_COLLECTION_DEV).doc("lobby");

    await firestore.runTransaction(async (t) => {
      let doc = await t.get(docRefDev);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "Event does not exist",
        };

        throw error;
      }
      try {
        t.update(docRefDev, {
          [property]: {
            ...data,
            timestamp: serverTimestamp,
          },
        });
        cb(true);
      } catch (err) {
        console.log(err);
        cb(false);
      }
    });

    return;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const updateVehicleData = async (data, cb) => {
  try {
    if (!data) throw new Error("Provide all arguments");
    // let docRef = firestore.collection(ASSETS_COLLECTION).doc("lobby");

    let docRefDev = firestore
      .collection(ASSETS_COLLECTION_DEV)
      .doc("vehicleData");

    await firestore.runTransaction(async (t) => {
      let doc = await t.get(docRefDev);
      if (!doc.exists) {
        const error = {
          code: "NotValidId",
          message: "Event does not exist",
        };

        throw error;
      }
      try {
        t.update(docRefDev, {
          urls: data,
        });
        cb(true);
      } catch (err) {
        console.log(err);
        cb(false);
      }
    });

    return;
  } catch (error) {
    console.log("Error :: ", error);
    throw error;
  }
};

const put = {
  updateAsset,
  updateVehicleData,
};

export default put;
