import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/database";
import "firebase/analytics";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBFUMWjonZjVvgxn2QOVpO5pSS55ANSGJM",
  authDomain: "expn-metaverse.firebaseapp.com",
  databaseURL:
    "https://expn-metaverse-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "expn-metaverse",
  storageBucket: "expn-metaverse.appspot.com",
  messagingSenderId: "197743052515",
  appId: "1:197743052515:web:8a4a0f661c92b69f20a7d6",
  measurementId: "G-G63KJMVFQ6",
};

firebase.initializeApp(firebaseConfig);

export default firebase;
export const firestore = firebase.firestore();
export const database = firebase.database();
export const analytics = firebase.analytics();
export const functions = firebase.functions();
export const storage = firebase.storage();

export const auth = firebase.auth();

export const login = (email, password) => {
  return new Promise(async (res, rej) => {
    try {
      const user = await auth.signInWithEmailAndPassword(email, password);
      if (user?.user?.email === "admin@espn.com") {
        res(user);
      } else {
        auth().signOut();
        rej(false);
      }
    } catch (error) {
      rej(false);
    }
  });
};
export const logout = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      // Sign-out successful.
    })
    .catch((error) => {
      // An error happened.
    });
};
