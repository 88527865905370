import React, { useContext, useEffect, useState } from "react";
import { ScreenContext } from "../../Contexts/ScreenContext/ScreenContextProvider";
import DualUploader from "../DualUploader";
import Loader from "../Loader";
import Nav from "../Nav";
import Uploader from "../Uploader";
import styles from "./index.module.scss";

function MainScreen() {
  const { sideScreen, details, vehicleData, loading } =
    useContext(ScreenContext);

  return (
    <div className={styles.MainWrapper}>
      <Nav />
      <div className={styles.sideWrapper}>
        {sideScreen === "vehicle" ? (
          vehicleData.length > 0 ? (
            vehicleData.map((vehicle, index) => {
              return <Uploader key={index} index={index} detail={vehicle} />;
            })
          ) : (
            <div className={styles.loadingWrapper}>
              <Loader />
            </div>
          )
        ) : details && Object.values(details).length > 0 ? (
          Object.values(details).map((detail, index) => (
            <div key={index}>
              <DualUploader detail={detail} />
            </div>
          ))
        ) : (
          <div className={styles.loadingWrapper}>
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
}

export default MainScreen;
