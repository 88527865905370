import {
  ASSETS_COLLECTION,
  ASSETS_COLLECTION_DEV,
} from "../../Constants/CollectionConstants";
import firebase, { firestore } from "../../firebase/firebase";

let listenerRef = null;
let vehicleRef = null;

const getAssets = async (callback = () => console.log("noFunFound")) => {
  try {
    listenerRef = firestore
      .collection(ASSETS_COLLECTION_DEV)
      .doc("lobby")
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          let data = documentSnapshot.data();
          callback(data);
        } else {
          callback(null);
        }
      });
  } catch (error) {
    callback(null);
    console.error("Error :: ", error);
  }
};

const getVehicles = async (callback = () => console.log("noFunFound")) => {
  try {
    vehicleRef = firestore
      .collection(ASSETS_COLLECTION_DEV)
      .doc("vehicleData")
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          let data = documentSnapshot.data();
          callback(data);
        } else {
          callback(null);
        }
      });
  } catch (error) {
    callback(null);
    console.error("Error :: ", error);
  }
};

const removeAssetsListener = () => {
  if (listenerRef) {
    listenerRef();
  }
};

const removeVehicleListener = () => {
  if (vehicleRef) {
    vehicleRef();
  }
};

const get = {
  getAssets,
  removeAssetsListener,
  getVehicles,
  removeVehicleListener,
};

export default get;
