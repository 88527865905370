import React, { useContext, useState } from "react";
import { uploadMedia } from "../../firebase/Storage";
import styles from "./index.module.scss";
import uploader from "../../Assets/images/uploader.png";
import AssetsManager from "../../Managers/Assets";
import { ScreenContext } from "../../Contexts/ScreenContext/ScreenContextProvider";
import success from "../../Assets/images/success.svg";
import info from "../../Assets/images/info.png";

const Uploader = ({ detail, index }) => {
  const { vehicleData, setVehicleData } = useContext(ScreenContext);
  const [showModal, setShowModal] = useState(false);
  const [loader, setLoader] = useState(0);
  const [change, SetChange] = useState(false);
  const [showPreviewModal, setShowPreviewModal] = useState(false);

  const handleImageUpload = async (e, type) => {
    if (e.target.files[0]) {
      let ref = `assets/vehicleData/`;
      let res = await uploadMedia(
        e.target.files[0],
        ref,
        `${index + 1}?x=${Math.random()}`,
        (progress) => {
          if (progress >= 100) alert("uploaded!!");
          setLoader(progress);
        }
      );
      SetChange(true);
      setVehicleData((prev) => {
        let _prev = [...prev];
        _prev[index] = res;
        return _prev;
      });
    }
  };

  const handleSave = () => {
    AssetsManager._put.updateVehicleData(vehicleData, (res) => {
      if (res) {
        SetChange(false);
        setShowModal(true);
        setLoader(0);
      }
    });
  };

  return (
    <div className={styles.uploaderWrapper}>
      {showModal && (
        <div className="modalWrapper">
          <div
            onClick={() => setShowModal(false)}
            className="modalOverlay"
          ></div>
          <div className="modal">
            <div className="successImg">
              <img src={success} alt="success" />
            </div>
            Succesfully Updated
          </div>
        </div>
      )}
      {showPreviewModal && (
        <div className="modalWrapper">
          <div
            onClick={() => setShowPreviewModal(false)}
            className="modalOverlay"
          ></div>
          <div
            style={{ background: "transparent", padding: 0 }}
            className="modal"
          >
            <div className={styles.prevImg}>
              <img src={`/${index + 1}.png`} alt="success" />
            </div>
          </div>
        </div>
      )}
      <div className={styles.highlight}>
        <div className={styles.highlightText}>
          <div className={styles.name}>{`Vehicle Image ${index + 1}`}</div>
          <div className={styles.location}>{detail.Label}</div>
        </div>
        <div className={styles.highlightBtns}>
          <img
            onClick={() => setShowPreviewModal(true)}
            className={styles.info}
            src={info}
            alt="img"
          />
          <div
            style={{
              backgroundColor: change ? "#1425ff" : "#d4d4d4",
            }}
            onClick={handleSave}
            className={styles.highlightBtn1}
          >
            Save
          </div>
        </div>
      </div>
      <div className={styles.banner1Btns}>
        {detail ? (
          <img className={styles.detailLink} src={detail} alt="detail" />
        ) : (
          <div className={styles.bannerImg}>{`${1080}x${1920} px`}</div>
        )}
        <div className={styles.banner1BtnsWrapper}>
          <input
            id={index + 1}
            onChange={(e) => handleImageUpload(e)}
            type="file"
            accept="Image/*"
            hidden
          />
          <label
            htmlFor={index + 1}
            type="submit"
            className={styles.banner1Btn}
          >
            <img src={uploader} alt="uploader" />
            Image
          </label>
          <div className={styles.bannerText}>
            <div>{`Supported file: PNG/ JPEG in ${1080} x ${1920} px`}</div>
            <div>{`Max file size: 1mb`}</div>
          </div>
        </div>
      </div>
      <div className={styles.loader}>
        <div style={{ width: `${loader}%` }} className={styles.progress}></div>
      </div>
    </div>
  );
};

export default Uploader;
